import { createStore, createLogger } from 'vuex'
import axios from 'axios'
import axiosInstance from '@/services/tokenService';
// import auth from './auth'
import setting from './setting'
import { jwtDecode } from "jwt-decode";

const API_URL = process.env.VUE_APP_API_URL;
const debug = process.env.NODE_ENV !== 'production'
const store = createStore({
  state: {
    user: JSON.parse(localStorage.getItem('user')) || null, // Load user from localStorage on init
    guestsData: {}, //activeMembers: [] 
    guestProfile: {},
    guestCertificates: [],

    certificateLevels: [],
    diveCenters: [],
    divingOrganizations: [],
    languages: [],
  },

  getters: {
    //shareOffcanvas: (state) => state.shareOffcanvas
    user: (state) => state.user,
    loggedIn: (state) => !!state.user,
    userName: (state) => state.user.userName,
    centerId: (state) => state.user.CenterId,
    guestProfile: (state) => state.guestProfile,
    guestCertificates: (state) => state.guestCertificates,

    certificateLevels: (state) => state.certificateLevels,
    diveCenters: (state) => state.diveCenters,
    divingOrganizations: (state) => state.divingOrganizations,
    languages: (state) => state.languages,
  },

  mutations: {
    SET_CERTIFICATE_LEVELS_OPTIONS(state, payload) {
      state.certificateLevels = payload
    },

    SET_DIVE_CENTERS_OPTIONS(state, payload) {
      state.diveCenters = payload
    },
    SET_DIVING_ORGANIZATIONS_OPTIONS(state, payload) {
      state.divingOrganizations = payload
    },
    SET_LANGUAGES_OPTIONS(state, payload) {
      state.languages = payload
    },


    SET_USER_DATA(state, userData) {
      const decodedToken = jwtDecode(userData?.accessToken);
      state.user = { ...userData, ...decodedToken }
      console.log(state.user);
      localStorage.setItem('user', JSON.stringify(state.user));
      axios.defaults.headers.common['Authorization'] = `Bearer ${userData.accessToken}`
    },

    ClEAR_USER_DATA() {
      localStorage.removeItem('user');
    },

    SET_NEW_TOKEN_UPDATE(state, newToken) {
      state.user.accessToken = newToken.accessToken
      state.user.refreshToken = newToken.refreshToken


      localStorage.setItem('user', JSON.stringify(state.user));//...newToken, state.user.userName
      console.log("Got a newToken:", state.user);
      axios.defaults.headers.common['Authorization'] = `Bearer ${newToken.accessToken}`
    },


    SET_GUEST_DETAILS(state, guestData) {
      state.guestProfile = guestData;
      state.guestCertificates = [
        { title: 'Certificate', image: guestData.certificatePhotoPath?.replace('https://scubaworlddivers.com/photos', '/proxyImg') },
        { title: 'Nitrox Certificate', image: guestData.nitroxCertificatePhotoPath?.replace('https://scubaworlddivers.com/photos', '/proxyImg') },
        { title: 'Medical Certificate', image: guestData.medicalInfoPhotoPath?.replace('https://scubaworlddivers.com/photos', '/proxyImg') },
        { title: 'Passport Photo', image: guestData.passportPhotoPath?.replace('https://scubaworlddivers.com/photos', '/proxyImg') },
      ]
    }
  },

  actions: {
    async refreshToken({ commit, state }) {
      try {

        console.log("refreshToken", state.user.refreshToken);
        //maybe we dont need to use axiosInstance here!! Yeah
        const response = await axios.post(`${API_URL}/auth/refresh-token`, {
          refreshToken: state.user.refreshToken, // Use refreshToken, not accessToken
        });
        console.log("refreshToken response", response);

        if (response && response.status === 200) {
          commit('SET_NEW_TOKEN_UPDATE', response.data);
          return response.data.accessToken;
        } else {
          console.error('Unexpected response from refresh token:', response);
          commit('CLEAR_USER_DATA');
          throw new Error('Failed to refresh token');
        }
      } catch (error) {
        console.error('Failed to refresh token', error);
        commit('CLEAR_USER_DATA'); // Logout if refresh token fails
      }
    },

    async login({ commit }, credentials) {
      const response = await axios.post(`${API_URL}/auth/login`, credentials); //${API_URL}

      if (response.status === 200 && response.data?.accessToken.length) {
        console.log(response.data?.accessToken);
        commit('SET_USER_DATA', response.data)
      }
    },

    logout({ commit }) {
      commit('ClEAR_USER_DATA')
    },

    getSelectDropdownsOptions({ commit, state }) {
      try {

        if (state.certificateLevels.length == 0)
          axiosInstance.get(`${API_URL}/certificate-level`).then((res) => {
            if (res.status === 200) commit('SET_CERTIFICATE_LEVELS_OPTIONS', res.data)
          });

        if (state.diveCenters.length == 0)
          axiosInstance.get(`${API_URL}/divecenter`).then((res) => {
            if (res.status === 200) commit('SET_DIVE_CENTERS_OPTIONS', res.data)
          });

        if (state.divingOrganizations.length == 0)
          axiosInstance.get(`${API_URL}/diving-organization`).then((res) => {
            if (res.status === 200) commit('SET_DIVING_ORGANIZATIONS_OPTIONS', res.data)
          });

        if (state.languages.length == 0)
          axiosInstance.get(`${API_URL}/language`).then((res) => {
            if (res.status === 200) commit('SET_LANGUAGES_OPTIONS', res.data)
          });
      }

      catch (error) {
        console.error(error)
      }
    },

    async getGuestDetails({ commit }, email) {
      await axiosInstance.get(`${API_URL}/member/${email}`)
        .then((response) => {
          console.log("member/$email");

          if (response.status === 200) commit('SET_GUEST_DETAILS', response.data);
        });
    }
  },

  modules: {
    // auth,
    setting,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
export default store;